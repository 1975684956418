<template>
    <base-sticky-heading content-class="bg-gray-50">
        <template #left>
            <div class="flex flex-row flex-wrap items-center gap-4 self-center">
                <strong class="py-2 text-xl font-medium text-gray-900">
                    {{
                        state === SUBSCRIPTION.TYPE_CONFIRMED
                            ? $t(
                                  'subscriptionExpenses.timesheet.confirmed.title'
                              )
                            : $t(
                                  'subscriptionExpenses.timesheet.possible.title'
                              )
                    }}
                </strong>
            </div>
        </template>
        <template #right>
            <div class="flex flex-col gap-x-3 gap-y-1 xl:flex-row">
                <base-button
                    class="!p-2"
                    variant="default"
                    :class="{
                        'bg-gray-100': layoutView === 'grid',
                    }"
                    @click="layoutView = 'grid'"
                >
                    <base-icon
                        size="md"
                        variant="gray"
                        name="line-icons:layout:grid"
                    />
                </base-button>

                <base-button
                    class="!p-2"
                    variant="default"
                    :class="{
                        'bg-gray-100': layoutView === 'row',
                    }"
                    @click="layoutView = 'row'"
                >
                    <base-icon
                        size="md"
                        variant="gray"
                        name="line-icons:editor:align-justify"
                    />
                </base-button>

                <base-button-group>
                    <base-button
                        variant="default"
                        :class="{
                            'bg-gray-100':
                                state === SUBSCRIPTION.TYPE_CONFIRMED,
                        }"
                        @click="viewChange(SUBSCRIPTION.TYPE_CONFIRMED)"
                    >
                        {{
                            $t(
                                'subscriptionExpenses.timesheet.transaction.confirmed'
                            )
                        }}
                    </base-button>
                    <base-button
                        variant="default"
                        @click="viewChange(SUBSCRIPTION.TYPE_POSSIBLE)"
                        :class="{
                            'bg-gray-100': state === SUBSCRIPTION.TYPE_POSSIBLE,
                        }"
                    >
                        {{
                            $t(
                                'subscriptionExpenses.timesheet.transaction.possible'
                            )
                        }}
                    </base-button>
                </base-button-group>
            </div>
        </template>

        <div class="w-full flex-1">
            <div v-if="isLoading" class="relative h-full w-full flex-1">
                <div
                    class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
                >
                    <span
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
                    >
                        <base-loading size="lg" />
                    </span>
                </div>
            </div>

            <div
                v-else
                :class="{
                    'p-6':
                        !hasPaymentAccount ||
                        (hasPaymentAccount && isAnalyzing),
                }"
            >
                <div
                    :class="{
                        'flex items-center justify-center self-stretch rounded-[5px] border border-gray-300 bg-white p-6 px-0 py-6':
                            !hasPaymentAccount ||
                            (hasPaymentAccount && isAnalyzing),
                    }"
                >
                    <subscription-expenses-overview-connect-bank-account
                        v-if="!hasPaymentAccount"
                        @add-bank-account="onAddBankAccount"
                    />

                    <subscription-expenses-overview-analyzing
                        v-if="hasPaymentAccount && isAnalyzing"
                        :loading="true"
                    />
                </div>
            </div>

            <subscription-expenses-timesheet-layout-row
                v-if="showRowLayout"
                :subscriptions="subscriptions"
                :state="state"
                :default-currency="defaultCurrency"
                :current-page="currentPage"
                :last-page="lastPage"
            />

            <subscription-expenses-timesheet-layout-grid
                v-if="showGridLayout"
                :subscriptions="subscriptions"
                :state="state"
                :default-currency="defaultCurrency"
                :current-page="currentPage"
                :last-page="lastPage"
            />
        </div>
    </base-sticky-heading>
</template>

<script setup>
import { useFilters } from '@tenant/composables'
import { SUBSCRIPTION } from '@tenant/modules/tenant/subscription-expenses/constant/subscription'
import { useSubscriptionExpensesTimesheet } from '@tenant/modules/tenant/subscription-expenses/composables/use-subscription-expenses-timesheet'
import { isEmpty } from 'lodash-es'

const {
    execute,
    result,
    loading: loadingPayments,
} = useApi('api/payment-accounts-total', 'GET')

const route = useRoute()
const queries = {
    type: 'filter.type',
}

const { filterValues, updateFilters } = useFilters(queries)

const {
    executeSearch,
    loading,
    subscriptions,
    isAnalyzing,
    currentPage,
    lastPage,
} = useSubscriptionExpensesTimesheet()
const { execute: executeSync } = useApi('api/subscription-chart-sync', 'POST')

const { defaultCurrency } = useCurrencies()
const state = ref(SUBSCRIPTION.TYPE_POSSIBLE)
const layoutView = ref('row')
const router = useRouter()

const viewChange = (type) => {
    state.value = type
    updateFilters({
        [queries.type]: type,
    })
}

onBeforeMount(() => {
    initType()
})

onMounted(async () => {
    await execute()
    if (hasPaymentAccount.value) {
        executeSync()

        await executeSearch({
            ...route?.query,
        })
    }
})

const initType = () => {
    const { type } = filterValues.value
    if (!type) {
        updateFilters({
            [queries.type]: SUBSCRIPTION.TYPE_POSSIBLE,
        })
        state.value = SUBSCRIPTION.TYPE_POSSIBLE
        return
    }

    state.value = type
}

const onAddBankAccount = () => {
    router.push({
        name: 'settings.accounting.payment-account.connect-bank',
    })
}

const hasPaymentAccount = computed(() => {
    return !!result.value?.total
})

const isLoading = computed(() => {
    return loading.value || loadingPayments.value
})

const showRowLayout = computed(() => {
    return (
        hasPaymentAccount.value &&
        !isAnalyzing.value &&
        layoutView.value === 'row' &&
        !isLoading.value
    )
})

const showGridLayout = computed(() => {
    return (
        hasPaymentAccount.value &&
        !isAnalyzing.value &&
        layoutView.value === 'grid' &&
        !isLoading.value
    )
})

watch(
    () => route?.query,
    () => {
        if (hasPaymentAccount.value) {
            return executeSearch({
                ...route?.query,
            })
        }
    },
    {
        immediate: !isEmpty(route?.query.filter) && hasPaymentAccount.value,
        flush: 'post',
    }
)
</script>
