<template>
    <base-dropdown variant="default" size="md" :btn-disabled="disabled">
        <template #btn-content>
            <span class="pr-10 text-md font-normal text-gray-900">
                {{ currentOption }}
            </span>
            <base-icon name="line-icons:arrows:chevron-down" variant="gray" />
        </template>

        <template #default="{ toggleDropdown }">
            <base-dropdown-item
                v-for="item in frequencies"
                :key="item.value"
                @click="
                    () => {
                        setFrequency(item.value)
                        toggleDropdown()
                    }
                "
            >
                <span class="text-md font-normal text-gray-500">
                    {{ item.label }}
                </span>
            </base-dropdown-item>
        </template>
    </base-dropdown>
</template>

<script setup>
const { t } = useI18n()

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
})

// Input handler
const emit = defineEmits(['update:modelValue'])
const value = computed({
    get() {
        return props.modelValue
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const frequencies = [
    {
        label: t(
            'subscriptionExpenses.timesheet.transaction.frequency.monthly'
        ),
        value: 'monthly',
    },
    {
        label: t(
            'subscriptionExpenses.timesheet.transaction.frequency.quarterly'
        ),
        value: 'quarterly',
    },
    {
        label: t('subscriptionExpenses.timesheet.transaction.frequency.yearly'),
        value: 'yearly',
    },
]

const currentOption = computed(() => {
    return frequencies.find((item) => item.value === value.value)?.label
})
const setFrequency = (v) => {
    value.value = v
}
</script>
