<template>
    <subscription-expenses-overview-most-table
        v-if="subscriptions"
        :columns="columns"
        :subscriptions="subscriptions"
        :loading="loading"
        :title="$t('subscriptionExpenses.overview.mostExpensive.title')"
        identify-field="id"
        @row-click="onOpenDetailView"
    >
        <template #column-frequency="{ item }">
            {{
                $t(
                    'subscriptionExpenses.timesheet.transaction.frequency.' +
                        item.frequency
                )
            }}
        </template>

        <template #column-app="{ item }">
            <div class="flex items-center gap-3 self-stretch">
                <div
                    v-if="item?.logoUrl"
                    class="h-[20px] w-[20px] rounded-[50%]"
                >
                    <img class="h-full w-full" :src="item.logoUrl" alt="" />
                </div>

                <div
                    v-else
                    class="flex h-[20px] w-[20px] items-start justify-start rounded-[50%]"
                >
                    <base-icon
                        variant="gray"
                        size="xl"
                        name="line-icons:images:image-03"
                        class="h-full w-full"
                    />
                </div>

                {{ item?.app }}
            </div>
        </template>

        <template #column-totalAmount="{ item }">
            {{
                defaultCurrency
                    ? $filters.symbolCurrency(item.totalAmount, defaultCurrency)
                    : ''
            }}
        </template>
    </subscription-expenses-overview-most-table>
</template>
<script setup>
const { t } = useI18n()

const router = useRouter()

defineProps({
    subscriptions: {
        type: Array,
        required: true,
    },
    defaultCurrency: {
        type: Object,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
})

const genericProperties = {
    headerCellClass: '!rounded-none',
    dataCellClass: '!text-gray-500 text-sm font-medium !py-4',
}

const columns = [
    {
        ...genericProperties,
        property: 'app',
        label: t(
            'subscriptionExpenses.overview.mostExpensive.table.header.app'
        ),
        sortable: true,
    },
    {
        ...genericProperties,
        property: 'frequency',
        label: t(
            'subscriptionExpenses.overview.mostExpensive.table.header.frequency'
        ),
        sortable: true,
    },
    {
        ...genericProperties,
        property: 'totalAmount',
        label: t(
            'subscriptionExpenses.overview.mostExpensive.table.header.totalAmount'
        ),
        sortable: true,
    },
]
const onOpenDetailView = (id) => {
    router.push({
        name: 'subscription-expenses.timesheet.detail',
        params: { id },
    })
}
</script>
