<template>
    <div
        class="mb-2.5 mt-4 flex h-[1px] items-start justify-center self-stretch bg-gray-300"
    ></div>

    <div class="flex flex-col items-start gap-[5px] self-stretch px-6">
        <subscription-expenses-card-body-line
            :label="
                $t(
                    'subscriptionExpenses.timesheet.transaction.card.merchantName'
                )
            "
        >
            {{ subscription.merchantName }}
        </subscription-expenses-card-body-line>

        <subscription-expenses-card-body-line
            :label="
                $t(
                    'subscriptionExpenses.timesheet.transaction.card.subscriptionStatus'
                )
            "
        >
            <template #value>
                <form-toggle
                    size="sm"
                    v-model="status"
                    @click="onToggleStatus"
                />
            </template>
        </subscription-expenses-card-body-line>

        <subscription-expenses-card-body-line
            :label="
                $t(
                    'subscriptionExpenses.timesheet.transaction.card.billingFrequency'
                )
            "
        >
            <template #value>
                <subscription-expenses-frequency-dropdown v-model="frequency" />
            </template>
        </subscription-expenses-card-body-line>

        <subscription-expenses-card-body-line
            :label="
                $t(
                    'subscriptionExpenses.timesheet.transaction.card.renewalDate'
                )
            "
            :show-border="false"
        >
            <template #value>
                <form-date-picker
                    size="md"
                    :full-width="false"
                    v-model="renewalDate"
                    :error-message="errorDate"
                    :placeholder="
                        $t(
                            'subscriptionExpenses.timesheet.transaction.card.unknown'
                        )
                    "
                    single
                    auto-apply
                    :clearable="false"
                    :loading="loading"
                />
            </template>
        </subscription-expenses-card-body-line>
    </div>
</template>

<script setup>
const { value: renewalDate, errorMessage: errorDate } =
    useField('next_payment_date')

const { value: frequency } = useField('frequency')

const { value: status } = useField('status')

defineProps({
    loading: {
        type: Boolean,
        required: true,
    },
    subscription: {
        type: Object,
        required: true,
    },
    defaultCurrency: {
        type: Object,
        required: true,
    },
})

const onToggleStatus = () => {
    status.value = !status.value
}
</script>
