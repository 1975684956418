<template>
    <div class="flex items-start gap-6 self-stretch px-6">
        <div v-if="item.logoUrl" class="h-16 w-16">
            <img :src="item.logoUrl" class="h-full w-full" alt="" />
        </div>

        <div v-else class="flex h-16 w-16 items-start justify-start">
            <base-icon
                variant="inherit"
                size="3xl"
                name="line-icons:images:image-03"
                class="h-full w-full"
            />
        </div>

        <div class="flex flex-1 flex-col items-start gap-1 self-stretch">
            <span class="text-sm font-medium text-gray-700">{{
                $t('subscriptionExpenses.timesheet.table.header.appName')
            }}</span>

            <form-text-input
                v-model="app"
                :error-message="errorApp"
                :disabled="loading"
                size="sm"
            />
        </div>
    </div>
</template>
<script setup>
const { value: app, errorMessage: errorApp } = useField('app')
defineProps({
    item: {
        type: Object,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
})
</script>
