<template>
    <div
        class="flex items-center justify-center self-stretch rounded-[5px] bg-white px-0"
    >
        <data-grid
            v-if="subscriptions"
            class="w-full !rounded-none border-l-0 border-r-0 border-t-0"
            :data-source="subscriptions"
            :columns="columns"
            identify-field="id"
            @row-click="onOpenDetailView"
        >
            <template #column-ytd="{ item }">
                {{
                    defaultCurrency
                        ? $filters.symbolCurrency(item.ytd, defaultCurrency)
                        : ''
                }}
            </template>

            <template #column-date="{ item }">
                {{ $filters.dateCustomFormat(item.date, 'DD MMMM YYYY') }}
            </template>

            <template #column-frequency="{ item }">
                {{
                    $t(
                        'subscriptionExpenses.timesheet.transaction.frequency.' +
                            item.frequency
                    )
                }}
            </template>

            <template #column-paymentMethod="{ item }">
                <div class="flex items-center gap-3 self-stretch">
                    <div class="h-[30px] w-[30px] rounded-[50%]">
                        <img
                            v-if="item?.paymentMethod?.logoUrl"
                            class="h-full w-full rounded-[50%]"
                            :src="item.paymentMethod.logoUrl"
                            alt=""
                        />
                    </div>

                    <span class="text-sm font-normal text-gray-500">
                        *** {{ item?.paymentMethod.number }}
                    </span>
                </div>
            </template>

            <template #column-review="{ item }">
                <base-button
                    variant="default"
                    size="sm"
                    @click="onOpenDetailView(item.id)"
                >
                    {{
                        state === SUBSCRIPTION.TYPE_POSSIBLE
                            ? $t(
                                  'subscriptionExpenses.timesheet.table.body.btnReview'
                              )
                            : $t(
                                  'subscriptionExpenses.timesheet.table.body.btnReviewed'
                              )
                    }}
                </base-button>
            </template>

            <template #footer>
                <grid-pagination :total="lastPage" :page="currentPage" />
            </template>
        </data-grid>
    </div>
</template>
<script setup>
import { SUBSCRIPTION } from '@tenant/modules/tenant/subscription-expenses/constant/subscription'

const { t } = useI18n()
const router = useRouter()

defineProps({
    defaultCurrency: {
        type: Object,
        required: true,
    },
    lastPage: {
        type: Number,
        required: true,
    },
    currentPage: {
        type: Number,
        required: true,
    },
    subscriptions: {
        type: Array,
        required: true,
    },
    state: {
        type: String,
        required: true,
        validate: (value) =>
            [SUBSCRIPTION.TYPE_POSSIBLE, SUBSCRIPTION.TYPE_CONFIRMED].includes(
                value
            ),
    },
})

const genericProperties = {
    headerCellClass: '!rounded-none',
    dataCellClass: '!text-gray-500 text-sm font-medium !py-4',
}

const columns = [
    {
        ...genericProperties,
        property: 'merchantName',
        label: t('subscriptionExpenses.timesheet.table.header.merchantName'),
    },
    {
        ...genericProperties,
        property: 'ytd',
        label: t('subscriptionExpenses.timesheet.table.header.ytd'),
    },
    {
        ...genericProperties,
        property: 'date',
        label: t('subscriptionExpenses.timesheet.table.header.date'),
    },
    {
        ...genericProperties,
        property: 'frequency',
        label: t('subscriptionExpenses.timesheet.table.header.frequency'),
    },
    {
        ...genericProperties,
        property: 'paymentMethod',
        label: t('subscriptionExpenses.timesheet.table.header.paymentMethod'),
        dataCellClass: '!text-gray-500 text-xs font-medium !py-2.5',
    },
    {
        ...genericProperties,
        property: 'transactions',
        label: t('subscriptionExpenses.timesheet.table.header.transactions'),
    },
    {
        ...genericProperties,
        property: 'review',
        label: t('subscriptionExpenses.timesheet.table.header.review'),
        dataCellClass: '!text-gray-500 text-xs font-medium !py-2',
    },
]

const onOpenDetailView = (id) => {
    router.push({
        name: 'subscription-expenses.timesheet.detail',
        params: { id },
    })
}
</script>
