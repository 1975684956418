export const useSubscriptionExpensesOverview = () => {
    const {
        execute: executeMostTransactionHighs,
        loading: loadingMostTransactionHighs,
    } = useApi('/api/subscription-most-transaction-highs', 'GET')
    const { execute: executeMostExpensive, loading: loadingMostExpensive } =
        useApi('/api/subscription-most-expensive', 'GET')
    const { execute: executeDifferPerMonth, loading: loadingDifferPerMonth } =
        useApi('/api/subscription-differ-amount-per-month', 'GET')
    const { execute: executeOverview, loading: loadingOverview } = useApi(
        '/api/subscription-overview',
        'GET'
    )
    const { execute: executeChart, loading: loadingChart } = useApi(
        '/api/subscription-chart',
        'GET'
    )

    const startDate = ref(null)
    const endDate = ref(null)
    const step = ref('')
    const totalExpenses = ref(0)
    const projectedTotalSpend = ref(0)
    const yearOverYearChange = ref('')
    const subscriptionCount = ref('')
    const expenses = ref([])
    const chartsData = ref(null)
    const mostExpensiveSubscription = ref(null)
    const monthlyTransactionHighs = ref(null)

    const execute = async (start, end, frequency) => {
        if (!start || !end || !frequency) {
            return
        }

        startDate.value = start
        endDate.value = end
        step.value = frequency

        _fetchMonthlyTransactionHighs()
        _fetchMostExpensiveSubscriptions()
        _fetchDifferExpensesPerMonth()
        _fetchSummary()
        _fetchChart()
    }

    const _fetchChart = async () => {
        const data = await executeChart({
            queryParams: {
                start_date: startDate.value,
                end_date: endDate.value,
                step: step.value,
            },
        })

        chartsData.value = data
    }

    const _fetchSummary = async () => {
        const data = await executeOverview({
            queryParams: {
                start_date: startDate.value,
                end_date: endDate.value,
                step: step.value,
            },
        })

        totalExpenses.value = data.total_expenses
        subscriptionCount.value = data.count_subscriptions.toString()
        yearOverYearChange.value =
            typeof data.year_over_year_change === 'number'
                ? data.year_over_year_change.toFixed(2) + '%'
                : '+∞'
        projectedTotalSpend.value = data.projected_expenses
    }

    const _fetchDifferExpensesPerMonth = async () => {
        const data = await executeDifferPerMonth({
            queryParams: {
                start_date: startDate.value,
                end_date: endDate.value,
            },
        })

        const finalizeData = []
        data.forEach((item) => {
            finalizeData.push({
                date: item.month,
                spend: item.current,
                difference: item.differ,
            })
        })

        expenses.value = finalizeData
    }

    const _fetchMonthlyTransactionHighs = async () => {
        const data = await executeMostTransactionHighs({
            queryParams: {
                start_date: startDate.value,
                end_date: endDate.value,
            },
        })

        const finalizeData = []
        data.forEach((item) => {
            finalizeData.push({
                period: item.month,
                totalTransactions: item.total_transactions,
                amount: item.total_amount,
            })
        })

        monthlyTransactionHighs.value = finalizeData
    }

    const _fetchMostExpensiveSubscriptions = async () => {
        const data = await executeMostExpensive({
            queryParams: {
                start_date: startDate.value,
                end_date: endDate.value,
            },
        })

        const finalizeData = []
        data.forEach((item) => {
            finalizeData.push({
                app: item.app,
                logoUrl: item.logo_url,
                frequency: item.frequency,
                totalAmount: item.total_amount,
                id: item.subscription_expense_id,
            })
        })

        mostExpensiveSubscription.value = finalizeData
    }

    return {
        execute,
        totalExpenses,
        projectedTotalSpend,
        yearOverYearChange,
        subscriptionCount,
        expenses,
        chartsData,
        mostExpensiveSubscription,
        monthlyTransactionHighs,
        loadingMostExpensive,
        loadingMostTransactionHighs,
        loadingDifferPerMonth,
        loadingOverview,
        loadingChart,
    }
}
