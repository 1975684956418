const navigation = [
    {
        id: 'sas-subscription-expenses',
        path: 'subscription-expenses',
        label: 'subscriptionExpenses.sidebar.label',
        icon: 'line-icons:files:clipboard-minus',
        position: 80,
        permission: 'view_subscription_expenses',
    },
    {
        id: 'sas-subscription-expenses-overview',
        path: 'subscription-expenses.overview',
        label: 'subscriptionExpenses.sidebar.overview.label',
        parent: 'sas-subscription-expenses',
        position: 1,
    },
    {
        id: 'sas-subscription-expenses-timesheet',
        path: 'subscription-expenses.timesheet',
        label: 'subscriptionExpenses.sidebar.timesheet.label',
        parent: 'sas-subscription-expenses',
        position: 1,
    },
]

export default navigation
