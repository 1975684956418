import { dayjsFormat } from '@tenant/utils/day'

export default {
    subscriptionExpenses: {
        sidebar: {
            label: 'Subscription Expenses',
            overview: {
                label: 'Overview',
            },
            timesheet: {
                label: 'Subscriptions',
            },
        },
        range: {
            lastMonth: 'Last Month',
            lastQuarter: 'Last Quarter',
            lastFinancialYear: 'Last Financial Year',
            yearToDate: 'Year to Date',
        },
        overview: {
            title: 'Expenses',
            noBankAccount: 'No bank account',
            noBankAccountDesc:
                'Connect a bank account to be able to track your subscription expenses.',
            addBankAccount: 'Add bank Account',
            analyzingLoading: 'analyzing ...',
            analyzingTitle: 'We’re analysing your data',
            analyzingDesc: 'This might take up to 30 minutes,',
            analyzingDesc2: 'please come back later.',
            chart: {
                totalExpenses: 'Total expenses',
                projectedTotalSpend: 'Projected total spend',
                yearOverYearChange: 'Year over year change',
                monthOverMonthChange: 'Month over month change',
                quarterOverQuarterChange: 'Quarter over quarter change',
                subscriptionCount: 'Subscription count',
                period: 'Period',
                spend: 'Spend',
                difference: 'Difference',
            },
            mostExpensive: {
                title: 'Most expensive',
                table: {
                    header: {
                        app: 'Subscription',
                        frequency: 'Frequency',
                        totalAmount: 'Spent',
                    },
                },
            },
            mostTransactionHighs: {
                title: 'Most transaction highs',
                table: {
                    header: {
                        period: 'Period',
                        totalTransactions: 'Number of transactions',
                        amount: 'Amount',
                    },
                },
            },
        },
        timesheet: {
            possible: {
                title: 'Possible subscriptions',
            },
            confirmed: {
                title: 'Confirmed subscriptions',
            },
            transaction: {
                confirmed: 'Confirmed',
                possible: 'Possible',
                frequency: {
                    monthly: 'Monthly',
                    yearly: 'Yearly',
                    quarterly: 'Quarterly',
                    null: 'Unknown',
                },
                card: {
                    btnIgnore: 'Ignore',
                    btnNotSubscription: 'Not a subscription',
                    btnConfirmSubscription: 'Confirm subscription',
                    btnRemoveSubscription: 'Remove subscription',
                    btnSaveSubscription: 'Save subscription',
                    merchantName: 'Merchant',
                    subscriptionStatus: 'Subscription status',
                    billingFrequency: 'Billing frequency',
                    renewalDate: 'Renewal date',
                    unknown: 'Unknown',
                },
                detail: {
                    transactions: 'Transactions',
                    comments: 'Comments',
                    activityLog: 'Activity log',
                    table: {
                        header: {
                            date: 'Date',
                            cost: 'Cost',
                            description: 'Description',
                            include: 'Include',
                            paymentMethod: 'Payment Method',
                        },
                    },
                    logDescription: ({ named }) => {
                        const event = named('event')
                        const oldValue = named('oldValue')
                        const newValue = named('newValue')
                        const mapping = {
                            monthly: 'Monthly',
                            yearly: 'Yearly',
                            quarterly: 'Quarterly',
                        }

                        switch (event) {
                            case 'frequency':
                                return `changed the billing frequency from ${
                                    oldValue ? mapping[oldValue] : 'empty'
                                } to ${newValue ? mapping[newValue] : 'empty'}.`
                            case 'app':
                                return `changed the associated application from ${
                                    oldValue ?? 'empty'
                                } to ${newValue}.`
                            case 'next_payment_date':
                                return `changed the renewal date from ${dayjsFormat(
                                    oldValue,
                                    'D. MMM. YYYY'
                                )} to ${dayjsFormat(newValue, 'D. MMM. YYYY')}.`
                            case 'status':
                                return (
                                    'set the status to ' +
                                    (newValue ? 'active' : 'inactive')
                                )
                            case 'confirmed':
                                return `confirmed this subscription.`
                            case 'possible':
                                return 'identified these transactions as a possible subscription.'
                            default:
                                return ''
                        }
                    },
                },
            },
            table: {
                header: {
                    merchantName: 'Merchant Name',
                    ytd: 'YTD',
                    date: 'Date',
                    frequency: 'Frequency',
                    paymentMethod: 'Payment account',
                    transactions: 'Transactions',
                    review: 'Review',
                    appName: 'App Name',
                },
                body: {
                    btnReview: 'Review',
                    btnReviewed: 'Reviewed',
                    btnVerify: 'Open to verify',
                },
            },
        },
    },
}
