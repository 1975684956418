<template>
    <base-sticky-heading content-class="bg-gray-50 p-6">
        <template #title>
            <strong class="py-2 text-xl font-medium text-gray-900">
                {{ $t('subscriptionExpenses.overview.title') }}
            </strong>
        </template>
        <template #right>
            <subscription-expenses-overview-chart-date-range
                v-if="showPanelChart"
                @update-range="updateRange"
                :disabled="isDisabled"
            />
        </template>

        <div
            class="relative flex h-full w-full flex-1 items-center justify-center"
            v-if="isLoading"
        >
            <base-loading size="lg" class="flex items-center justify-center" />
        </div>

        <div v-else class="w-full">
            <div
                v-if="!showPanelChart"
                class="flex items-center justify-center self-stretch rounded-[5px] border border-gray-300 bg-white px-0 py-6"
            >
                <subscription-expenses-overview-connect-bank-account
                    v-if="!hasPaymentAccount"
                    @add-bank-account="onAddBankAccount"
                />
                <subscription-expenses-overview-analyzing
                    v-if="isAnalyzing && hasPaymentAccount"
                    :loading="!isLoading"
                />
            </div>
            <subscription-expenses-overview-chart
                v-if="showPanelChart && startDate && endDate"
                :start-date="startDate"
                :end-date="endDate"
                :step="step"
            />
        </div>
    </base-sticky-heading>
</template>

<script setup>
import { useSubscriptionExpensesTimesheet } from '@tenant/modules/tenant/subscription-expenses/composables/use-subscription-expenses-timesheet'

const emitter = useEmitter()

const {
    execute,
    result,
    loading: loadingPayments,
} = useApi('api/payment-accounts-total', 'GET')
const { executeSearch, loading, isAnalyzing } =
    useSubscriptionExpensesTimesheet()

const { execute: executeSync } = useApi('api/subscription-chart-sync', 'POST')

onMounted(async () => {
    await execute()
    if (hasPaymentAccount.value) {
        executeSync()

        await executeSearch()
    }
})

const startDate = ref(null)
const endDate = ref(null)
const step = ref('monthly')

const router = useRouter()

const hasPaymentAccount = computed(() => {
    return !!result.value?.total
})

const isLoading = computed(() => {
    return loading.value || loadingPayments.value
})

const showPanelChart = computed(() => {
    return !isAnalyzing.value && hasPaymentAccount.value && !isLoading.value
})

const isDisabled = ref(false)

const onAddBankAccount = () => {
    router.push({
        name: 'settings.accounting.payment-account.connect-bank',
    })
}

const updateRange = (range) => {
    startDate.value = range.fromDate
    endDate.value = range.toDate
    step.value = range.step
    isDisabled.value = true
}

onMounted(() => {
    emitter.on('se-loaded', () => {
        isDisabled.value = false
    })
})
</script>
