<template>
    <div v-if="loadingLogs" class="relative h-full w-full">
        <div
            class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
        >
            <span
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            >
                <base-loading size="lg" />
            </span>
        </div>
    </div>
    <div v-else class="py-[25px]">
        <div class="flex flex-col gap-4">
            <subscription-expenses-log-item
                v-for="log in logs"
                :key="log.id"
                :log="log"
                :causer="log.causer ?? companyInfo"
            />
        </div>
    </div>
</template>

<script setup>
import { useSubscriptionExpensesTimesheetDetail } from '@tenant/modules/tenant/subscription-expenses/composables/use-subscription-expenses-timesheet-detail'

const route = useRoute()
const { useApiGet } = useApiFactory('companies')
const { executeLogs, loadingLogs } = useSubscriptionExpensesTimesheetDetail()
const { execute: executeGet } = useApiGet()

const companyInfo = ref(null)
const logs = ref([])

const fetchLogs = async () => {
    logs.value = await executeLogs(route.params.id)
}

onMounted(async () => {
    await fetchLogs()
    const { company, info } = await executeGet()
    const { company_name } = company
    const { logo } = info || {}

    companyInfo.value = {
        full_name: company_name,
        avatar: {
            path: logo?.preview?.path,
        },
    }
})
</script>
