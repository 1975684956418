<template>
    <data-grid
        class="w-full !rounded-none !border-l-0 !border-r-0 !border-t-0"
        :data-source="transactions"
        :selectable="true"
        :loading="loading"
        :columns="columns"
    >
        <template #header-select-all>
            {{
                $t(
                    'subscriptionExpenses.timesheet.transaction.detail.table.header.include'
                )
            }}
        </template>

        <template #column-date="{ item }">
            {{ $filters.dateCustomFormat(item.date, 'DD MMMM YYYY') }}
        </template>

        <template #column-cost="{ item }">
            {{
                defaultCurrency
                    ? $filters.symbolCurrency(item.cost, defaultCurrency)
                    : ''
            }}
        </template>

        <template #column-paymentMethod="{ item }">
            <div class="flex items-center gap-3 self-stretch">
                <div class="h-[30px] w-[30px]">
                    <img
                        v-if="item?.paymentMethod?.logoUrl"
                        class="h-full w-full rounded-[50%]"
                        :src="item.paymentMethod.logoUrl"
                        alt=""
                    />
                </div>

                <span class="text-sm font-normal text-gray-500">
                    *** {{ item.paymentMethod.number }}
                </span>
            </div>
        </template>
    </data-grid>
</template>

<script setup>
const { t } = useI18n()

defineProps({
    loading: {
        type: Boolean,
        required: true,
    },
    transactions: {
        type: Array,
        required: true,
        default: () => [],
    },
    defaultCurrency: {
        type: Object,
        required: true,
    },
})

const genericProperties = {
    headerCellClass: '!rounded-none',
    dataCellClass: '!text-gray-500 text-xs font-medium !py-4',
}

const columns = [
    ...['date', 'description', 'cost'].map((property) => ({
        ...genericProperties,
        property,
        label: t(
            `subscriptionExpenses.timesheet.transaction.detail.table.header.${property}`
        ),
        dataCellClass: '!text-gray-500 !text-sm',
    })),
    {
        ...genericProperties,
        property: 'paymentMethod',
        label: t(
            'subscriptionExpenses.timesheet.transaction.detail.table.header.paymentMethod'
        ),
        dataCellClass: '!text-gray-500 !text-sm !py-0',
    },
]
</script>
