import { watch } from 'vue'
export const useSubscriptionExpensesTimesheet = () => {
    const subscriptions = ref([])
    const isAnalyzing = ref(true)

    const { entities, useApiSearch } = useApiFactory('subscription-expenses')
    const { execute: executeSearch, loading } = useApiSearch({}, true, true)
    const lastPage = ref(0)
    const currentPage = ref(0)

    const hydrateData = () => {
        if (!Array.isArray(entities.value?.data)) {
            isAnalyzing.value = true

            return
        }

        const finalizeData = []

        entities.value.data.forEach((item) => {
            finalizeData.push({
                id: item.id,
                type: item.type,
                merchantName: item.merchant_name,
                app: item.app,
                logoUrl: item.logo_url,
                ytd: item.ytd,
                date: item.next_payment_date,
                frequency: item.frequency,
                paymentMethod: {
                    logoUrl:
                        item.payment_account?.payment_connector?.provider_logo,
                    number: item.payment_account?.iban?.slice(-4),
                },
                transactions: item.total_transactions,
            })
        })

        currentPage.value = entities.value.current_page
        lastPage.value = entities.value.last_page

        isAnalyzing.value = false
        subscriptions.value = finalizeData
    }

    watch(entities, () => hydrateData())

    return {
        executeSearch,
        subscriptions,
        loading,
        isAnalyzing,
        currentPage,
        lastPage,
    }
}
