<template>
    <div class="flex flex-col items-start gap-2.5 self-stretch px-6">
        <subscription-expenses-card-button @click="openToVerify(item.id)">
            {{
                state === SUBSCRIPTION.TYPE_POSSIBLE
                    ? $t('subscriptionExpenses.timesheet.table.body.btnVerify')
                    : $t(
                          'subscriptionExpenses.timesheet.table.body.btnReviewed'
                      )
            }}
        </subscription-expenses-card-button>
    </div>
</template>
<script setup>
import { SUBSCRIPTION } from '@tenant/modules/tenant/subscription-expenses/constant/subscription'

const router = useRouter()

defineProps({
    item: {
        type: Object,
        required: true,
    },
    state: {
        type: String,
        required: true,
        validate: (value) =>
            [SUBSCRIPTION.TYPE_POSSIBLE, SUBSCRIPTION.TYPE_CONFIRMED].includes(
                value
            ),
    },
})

const openToVerify = (id) => {
    router.push({
        name: 'subscription-expenses.timesheet.detail',
        params: {
            id,
        },
    })
}
</script>
