<template>
    <div v-if="loadingRoom" class="relative h-full w-full">
        <div
            class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
        >
            <span
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            >
                <base-loading size="lg" />
            </span>
        </div>
    </div>
    <subscription-expenses-detail-comment-chat
        v-if="!loadingRoom && resultGetRoom"
        :room="resultGetRoom"
    />
</template>
<script setup>
import { useSubscriptionExpensesTimesheetDetail } from '@tenant/modules/tenant/subscription-expenses/composables/use-subscription-expenses-timesheet-detail'

const route = useRoute()

const { executeRoom, loadingRoom, resultGetRoom } =
    useSubscriptionExpensesTimesheetDetail()

onMounted(async () => {
    await executeRoom(route.params.id)
})
</script>
