<template>
    <div
        class="col-span-3 flex flex-1 flex-col gap-2.5 self-stretch rounded-[5px] border border-gray-300 bg-white"
    >
        <div class="flex items-start self-stretch px-6 pb-2 pt-5">
            <h3 class="text-lg font-medium text-gray-900">
                {{ title }}
            </h3>
        </div>

        <data-grid
            v-if="rows"
            class="w-full !rounded-none border-b-0 border-l-0 border-r-0"
            :data-source="rows"
            :columns="columns"
            :loading="loading"
            :identify-field="identifyField"
            @row-click="onOpenDetailView"
        >
            <template
                v-for="column in columns"
                :key="column.property"
                v-slot:[`column-${column.property}`]="{ item }"
            >
                <slot :name="`column-${column.property}`" v-bind="{ item }">
                    {{ item[column.property] }}
                </slot>
            </template>
        </data-grid>
    </div>
</template>
<script setup>
const emit = defineEmits(['row-click'])
const route = useRoute()

const rows = ref([])
const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    columns: {
        type: Array,
        required: true,
    },
    subscriptions: {
        type: Array,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
    identifyField: {
        type: String,
        default: 'id',
    },
})

watch(
    props,
    (value) => {
        if (!value || !value.subscriptions) {
            return
        }

        rows.value = value?.subscriptions
    },
    {
        deep: true,
        immediate: true,
    }
)

const sortBy = computed(() => {
    return route.query?.sort?.by
})

const sortColumn = computed(() => {
    return route.query?.sort?.col
})

watch(
    [sortBy, sortColumn],
    ([sortBy, sortColumn]) => {
        if (sortBy && sortColumn) {
            if (
                !props.columns.find((column) => column.property === sortColumn)
            ) {
                return
            }

            rows.value = rows.value.sort((a, b) => {
                if (sortBy === 'asc') {
                    return a[sortColumn] > b[sortColumn] ? 1 : -1
                }

                return b[sortColumn] > a[sortColumn] ? 1 : -1
            })
        }
    },
    {
        immediate: true,
    }
)

const onOpenDetailView = (id) => {
    emit('row-click', id)
}
</script>
