<template>
    <div class="flex items-center gap-6 self-stretch">
        <subscription-expenses-overview-chart-sumarize-box
            :title="$t('subscriptionExpenses.overview.chart.totalExpenses')"
            :value="$filters.symbolCurrency(totalExpenses, defaultCurrency)"
            :loading="loading"
        />
        <subscription-expenses-overview-chart-sumarize-box
            :title="
                $t('subscriptionExpenses.overview.chart.projectedTotalSpend')
            "
            :value="
                $filters.symbolCurrency(projectedTotalSpend, defaultCurrency)
            "
            :loading="loading"
        />
        <subscription-expenses-overview-chart-sumarize-box
            :title="$t(overchangeTitle)"
            :value="yearOverYearChange"
            :loading="loading"
        />
        <subscription-expenses-overview-chart-sumarize-box
            :title="$t('subscriptionExpenses.overview.chart.subscriptionCount')"
            :value="subscriptionCount"
            :loading="loading"
        />
    </div>
</template>
<script setup>
const props = defineProps({
    defaultCurrency: {
        type: Object,
        required: true,
    },
    totalExpenses: {
        type: Number,
        required: true,
    },
    projectedTotalSpend: {
        type: Number,
        required: true,
    },
    yearOverYearChange: {
        type: String,
        required: true,
    },
    subscriptionCount: {
        type: String,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
    step: {
        type: String,
        required: true,
        validate: (value) => ['monthly', 'quarterly', 'yearly'].includes(value),
    },
})

const overchangeTitle = computed(() => {
    switch (props.step) {
        case 'monthly':
            return 'subscriptionExpenses.overview.chart.monthOverMonthChange'
        case 'quarterly':
            return 'subscriptionExpenses.overview.chart.quarterOverQuarterChange'
        default:
            return 'subscriptionExpenses.overview.chart.yearOverYearChange'
    }
})
</script>
