export const useSubscriptionExpensesTimesheetDetail = () => {
    const { execute: executeGet, result: resultGet } = useApi(
        `/api/subscription-expenses/{id}`,
        'GET'
    )

    const {
        execute: executeGetLogs,
        result: resultGetLogs,
        loading: loadingLogs,
    } = useApi(`/api/subscription-expenses/{id}/logs`, 'GET')

    const {
        execute: executeGetRoom,
        result: resultGetRoom,
        loading: loadingRoom,
    } = useApi(`/api/subscription-expenses/{id}/room`, 'GET')

    const { execute: executeSave } = useApi(
        `/api/subscription-expenses/{id}`,
        'POST'
    )

    const { execute: executeIgnore } = useApi(
        `/api/subscription-expenses/{id}/ignore`,
        'POST'
    )

    const { execute: executeNotSubscription } = useApi(
        `/api/subscription-expenses/{id}/not-subscription`,
        'POST'
    )

    const { execute: executeConfirm } = useApi(
        `/api/subscription-expenses/{id}/confirm-subscription`,
        'POST'
    )

    const subscription = ref({})
    const loading = ref(false)

    const execute = async (id) => {
        loading.value = true
        await executeGet({
            params: { id },
        })

        const transactions = resultGet.value.transactions.map((item) => ({
            id: item.id,
            date: item.date_of_transfer,
            description: item.description,
            cost: Math.abs(item.amount),
            paymentMethod: {
                logoUrl: item.payment_account?.payment_connector?.provider_logo,
                number: item.payment_account?.iban?.slice(-4),
            },
        }))

        subscription.value = {
            id,
            type: resultGet.value.type,
            merchantName: resultGet.value.merchant_name,
            status: resultGet.value.status,
            app: resultGet.value.app,
            logoUrl: resultGet.value.logo_url,
            ytd: Math.abs(resultGet.value.ytd),
            renewalDate: resultGet.value.next_payment_date,
            frequency: resultGet.value.frequency,
            paymentMethod: {
                logoUrl:
                    resultGet.payment_account?.payment_connector?.provider_logo,
                number: resultGet.payment_account?.iban?.slice(-4),
            },
            transactions: transactions,
        }

        loading.value = false
    }

    const executeLogs = async (id) => {
        await executeGetLogs({
            params: { id },
        })

        const logs = []

        if (resultGetLogs.value.activities) {
            resultGetLogs.value.activities.forEach((item) => {
                if (
                    typeof item?.properties === 'object' &&
                    typeof item.properties.attributes === 'object' &&
                    typeof item.properties.old === 'object'
                ) {
                    Object.keys(item.properties.attributes).forEach((key) => {
                        logs.push({
                            id: item.id + key,
                            diffDate: item.diff_date,
                            eventName: key,
                            oldValue: item.properties.old[key],
                            newValue: item.properties.attributes[key],
                            causer: item.causer,
                            createdAt: item.created_at,
                        })
                    })
                } else {
                    logs.push({
                        id: item.id,
                        diffDate: item.diff_date,
                        eventName: item.event,
                        oldValue: null,
                        newValue: null,
                        causer: item.causer,
                        createdAt: item.created_at,
                    })
                }
            })
        }

        return logs
    }

    const executeRoom = async (id) => {
        return await executeGetRoom({
            params: { id },
        })
    }

    const save = async (id, data) => {
        loading.value = true
        await executeSave({
            params: { id },
            data,
        })
        loading.value = false
    }

    const ignore = async (id) => {
        loading.value = true
        await executeIgnore({
            params: { id },
        })
        loading.value = false
    }

    const confirm = async (id) => {
        loading.value = true
        await executeConfirm({
            params: { id },
        })
        loading.value = false
    }

    const notSubscription = async (id) => {
        loading.value = true
        await executeNotSubscription({
            params: { id },
        })
        loading.value = false
    }

    return {
        execute,
        executeLogs,
        loadingLogs,
        executeRoom,
        loadingRoom,
        resultGetRoom,
        subscription,
        loading,
        save,
        ignore,
        confirm,
        notSubscription,
    }
}
