<template>
    <div class="flex w-[352px] flex-col items-center gap-6">
        <div class="flex flex-col items-center self-stretch">
            <base-feature-icon
                class="mb-4"
                size="lg"
                variant="primary"
                name="line-icons:general:search-lg"
            />
            <div class="flex flex-col items-center">
                <h3 class="text-center text-md font-medium text-gray-900">
                    {{ $t('subscriptionExpenses.overview.noBankAccount') }}
                </h3>
                <span
                    class="pt-1 text-center text-sm font-normal text-gray-500"
                >
                    {{ $t('subscriptionExpenses.overview.noBankAccountDesc') }}
                </span>
            </div>
        </div>
        <div class="flex flex-col items-center self-stretch">
            <base-button
                size="md"
                variant="primary"
                icon="line-icons:general:plus"
                @click="onAddBankAccount"
            >
                {{ $t('subscriptionExpenses.overview.addBankAccount') }}
            </base-button>
        </div>
    </div>
</template>
<script setup lang="ts">
const emit = defineEmits(['addBankAccount'])

const onAddBankAccount = () => {
    emit('addBankAccount')
    // TODO AC-1735: Add the logic to open the connect bank account modal
}
</script>
