<template>
    <div
        class="col-span-2 flex h-[452px] flex-1 flex-col items-start overflow-auto rounded-[5px] border border-gray-300 bg-white"
    >
        <data-grid
            v-if="expenses"
            class="w-full !rounded-none border-0"
            :data-source="expenses"
            :columns="columns"
            :loading="loading"
        >
            <template #column-date="{ item }">
                {{ $filters.dateCustomFormat(item.date, 'MMMM YYYY') }}
            </template>

            <template #column-spend="{ item }">
                {{ $filters.symbolCurrency(item.spend, defaultCurrency) }}
            </template>

            <template #column-different="{ item }">
                <base-badge
                    :label="displayDifference(item.difference)"
                    size="sm"
                    :variant="item.difference > 0 ? 'danger' : 'success'"
                    class="gap-1.5 !px-1.5"
                />
            </template>
        </data-grid>
    </div>
</template>
<script setup>
import { symbolCurrency } from '@tenant/core/filter'

const { t } = useI18n()

const props = defineProps({
    expenses: {
        type: Array,
        required: true,
    },
    defaultCurrency: {
        type: Object,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
})

const displayDifference = (difference) => {
    if (difference > 0) {
        return `+${symbolCurrency(difference, props.defaultCurrency)}`
    }

    return `- ${symbolCurrency(Math.abs(difference), props.defaultCurrency)}`
}

const genericProperties = {
    headerCellClass: '!rounded-none',
    dataCellClass: '!text-gray-500 text-sm font-medium !py-4',
}

const columns = [
    {
        ...genericProperties,
        property: 'date',
        label: t('subscriptionExpenses.overview.chart.period'),
    },
    {
        ...genericProperties,
        property: 'spend',
        label: t('subscriptionExpenses.overview.chart.spend'),
    },
    {
        ...genericProperties,
        property: 'different',
        label: t('subscriptionExpenses.overview.chart.difference'),
        sortable: true,
    },
]
</script>
