<template>
    <subscription-expenses-overview-most-table
        v-if="subscriptions"
        :columns="columns"
        :subscriptions="subscriptions"
        :loading="loading"
        :title="$t('subscriptionExpenses.overview.mostTransactionHighs.title')"
    >
        <template #column-period="{ item }">
            {{ $filters.dateCustomFormat(item.period, 'MMMM YYYY') }}
        </template>

        <template #column-amount="{ item }">
            {{
                defaultCurrency
                    ? $filters.symbolCurrency(item.amount, defaultCurrency)
                    : ''
            }}
        </template>
    </subscription-expenses-overview-most-table>
</template>
<script setup>
const { t } = useI18n()
defineProps({
    subscriptions: {
        type: Array,
        required: true,
    },
    defaultCurrency: {
        type: Object,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
})

const genericProperties = {
    headerCellClass: '!rounded-none',
    dataCellClass: '!text-gray-500 text-sm font-medium !py-4',
}

const columns = [
    {
        ...genericProperties,
        property: 'period',
        label: t(
            'subscriptionExpenses.overview.mostTransactionHighs.table.header.period'
        ),
    },
    {
        ...genericProperties,
        property: 'totalTransactions',
        label: t(
            'subscriptionExpenses.overview.mostTransactionHighs.table.header.totalTransactions'
        ),
    },
    {
        ...genericProperties,
        property: 'amount',
        label: t(
            'subscriptionExpenses.overview.mostTransactionHighs.table.header.amount'
        ),
        sortable: true,
    },
]
</script>
