<template>
    <div
        v-if="chartsData"
        class="relative flex flex-1 flex-col gap-2.5 self-stretch rounded-[5px] border border-gray-300 bg-white"
    >
        <div
            v-if="loading"
            class="absolute bottom-0 left-0 right-0 top-0 z-50 bg-white opacity-50"
        >
            <span
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform"
            >
                <base-loading size="lg" />
            </span>
        </div>

        <div class="relative h-full max-h-full max-w-full flex-1 p-6">
            <stackbar-chart
                :chart-data="chartData"
                :chart-options="chartOptions"
            />
        </div>
    </div>
</template>
<script setup>
import dayjs from 'dayjs'
import { numberFormat } from '@tenant/utils/helper'

const props = defineProps({
    chartsData: {
        type: Object,
        required: true,
    },
    loading: {
        type: Boolean,
        required: true,
    },
    step: {
        type: String,
        required: true,
        validate: (value) => ['monthly', 'quarterly', 'yearly'].includes(value),
    },
})

const dates = computed(() => {
    return props.chartsData.labels
})

const data = computed(() => {
    const items = []
    dates.value.forEach((date) => {
        const index = date.start
        items.push(
            props.chartsData.expenses[index] !== undefined
                ? props.chartsData.expenses[index]
                : props.chartsData.projected_expenses[index]
        )
    })

    return items
})

const backgroundColors = computed(() => {
    const items = []
    dates.value.forEach((date) => {
        const index = date.start
        items.push(
            props.chartsData.expenses[index] !== undefined
                ? '#7F56D9'
                : '#D0D5DD'
        )
    })

    return items
})

const chartOptions = computed(() => {
    return {
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: function () {
                    switch (props.step) {
                        case 'monthly':
                            return 'Day'
                        case 'quarterly':
                            return 'Week'
                        case 'yearly':
                            return 'Month'
                        default:
                            break
                    }
                },
                position: 'bottom',
                font: {
                    weight: 'normal',
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label: function (context) {
                        const label = context.dataset.data[context.dataIndex]

                        return numberFormat(label)
                    },

                    title: function (context) {
                        const range = dates.value[context[0].dataIndex]

                        switch (props.step) {
                            case 'monthly':
                                return dayjs(range.start).format('DD MMM')
                            case 'quarterly':
                                return (
                                    dayjs(range.start).format('DD MMM') +
                                    ' - ' +
                                    dayjs(range.end).format('DD MMM')
                                )
                            case 'yearly':
                                return dayjs(range.start).format('MMM')
                        }
                    },
                },
            },
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                border: {
                    display: false,
                },
                grid: {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    autoSkip: false,
                    source: 'data',
                    callback: function (val, index) {
                        const range = dates.value[index]

                        switch (props.step) {
                            case 'monthly':
                                return dayjs(range.start).format('DD MMM')
                            case 'quarterly':
                                return (
                                    dayjs(range.start).format('DD MMM') +
                                    ' - ' +
                                    dayjs(range.end).format('DD MMM')
                                )
                            case 'yearly':
                                return dayjs(range.start).format('MMM')
                        }
                    },
                },
            },
            y: {
                offset: false,
                grid: {
                    drawBorder: false,
                    display: true,
                },
                border: {
                    display: false,
                },
                ticks: {
                    padding: 10,
                },
                beginAtZero: true,
                max: ({ chart }) => {
                    let datasetMax = 0
                    chart.data.datasets.forEach((dataset) => {
                        datasetMax = Math.max(...(dataset.data ?? []))
                    })

                    return Math.max(
                        chart.options.scales.y.suggestedMax,
                        datasetMax
                    )
                },
            },
        },
    }
})

const chartData = computed(() => ({
    labels: dates.value,
    datasets: [
        {
            data: data.value,
            backgroundColor: backgroundColors.value,
            borderRadius: 5,
            barThickness: function () {
                switch (props.step) {
                    case 'monthly':
                        return 'flex'
                    default:
                        return 40
                }
            },
        },
    ],
}))
</script>
