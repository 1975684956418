<template>
    <div class="grid grid-cols-6 items-start gap-6 self-stretch">
        <div class="col-span-4 flex flex-col items-start gap-6 self-stretch">
            <subscription-expenses-overview-chart-sumarize
                v-if="defaultCurrency"
                :defaultCurrency="defaultCurrency"
                :totalExpenses="totalExpenses"
                :projectedTotalSpend="projectedTotalSpend"
                :yearOverYearChange="yearOverYearChange"
                :subscriptionCount="subscriptionCount"
                :loading="loadingOverview"
                :step="step"
            />

            <subscription-expenses-overview-chart-bar-chart
                v-if="chartsData"
                :chartsData="chartsData"
                :loading="loadingChart"
                :step="step"
            />
        </div>

        <subscription-expenses-overview-chart-table
            v-if="defaultCurrency"
            :defaultCurrency="defaultCurrency"
            :expenses="expenses"
            :loading="loadingDifferPerMonth"
        />

        <subscription-expenses-overview-grid-most-expensive
            v-if="mostExpensiveSubscription && defaultCurrency"
            :subscriptions="mostExpensiveSubscription"
            :defaultCurrency="defaultCurrency"
            :loading="loadingMostExpensive"
        />
        <subscription-expenses-overview-grid-most-transaction-highs
            v-if="monthlyTransactionHighs && defaultCurrency"
            :subscriptions="monthlyTransactionHighs"
            :defaultCurrency="defaultCurrency"
            :loading="loadingMostTransactionHighs"
        />
    </div>
</template>
<script setup>
import { useSubscriptionExpensesOverview } from '@tenant/modules/tenant/subscription-expenses/composables/use-subscription-expenses-overview'

const emitter = useEmitter()
const props = defineProps({
    startDate: {
        type: String,
        required: true,
    },
    endDate: {
        type: String,
        required: true,
    },
    step: {
        type: String,
        required: true,
        validate: (value) => ['monthly', 'quarterly', 'yearly'].includes(value),
    },
})
const { defaultCurrency } = useCurrencies()
const {
    totalExpenses,
    projectedTotalSpend,
    yearOverYearChange,
    subscriptionCount,
    expenses,
    chartsData,
    monthlyTransactionHighs,
    mostExpensiveSubscription,
    loadingDifferPerMonth,
    loadingMostExpensive,
    loadingMostTransactionHighs,
    loadingOverview,
    loadingChart,
    execute,
} = useSubscriptionExpensesOverview()

onMounted(() => {
    execute(props.startDate, props.endDate, props.step)
})

watch(props, () => {
    execute(props.startDate, props.endDate, props.step)
})

const allLoaded = computed(() => {
    return (
        !loadingDifferPerMonth.value &&
        !loadingMostExpensive.value &&
        !loadingMostTransactionHighs.value &&
        !loadingOverview.value &&
        !loadingChart.value
    )
})

watch(
    () => allLoaded.value,
    (value) => {
        if (value) {
            emitter.emit('se-loaded', true)
        }
    }
)
</script>
