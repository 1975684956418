<template>
    <div
        class="flex flex-col items-center justify-center gap-x-2.5 gap-y-1 xl:flex-row"
    >
        <subscription-expenses-frequency-dropdown
            v-model="frequency"
            :disabled="disabled"
        />

        <form-date-picker
            size="md"
            v-model="fromDate"
            :full-width="false"
            content-container-class="!pr-0"
            :placeholder="
                $t('subscriptionExpenses.timesheet.transaction.card.unknown')
            "
            single
            auto-apply
            :clearable="false"
            :disabled="disabled"
            @update:model-value="onchangeFromDate"
            custom-display-format="DD. MMMM YYYY"
        >
            <template #icon>
                <base-icon
                    name="line-icons:arrows:chevron-left"
                    variant="inherit"
                    @click="leftBack"
                />
            </template>
        </form-date-picker>

        <span class="text-sm font-medium lowercase">
            {{ $t('filters.to') }}
        </span>
        <form-date-picker
            size="md"
            v-model="toDate"
            content-container-class="!pr-0"
            :full-width="false"
            :disabled="disabled"
            :placeholder="
                $t('subscriptionExpenses.timesheet.transaction.card.unknown')
            "
            single
            auto-apply
            @update:model-value="onchangeToDate"
            :clearable="false"
            custom-display-format="DD. MMMM YYYY"
        >
            <template #icon>
                <span></span>
            </template>
            <template #right-icon>
                <base-icon
                    name="line-icons:arrows:chevron-right"
                    variant="inherit"
                    @click="rightForward"
                />
            </template>
        </form-date-picker>
    </div>
</template>
<script setup>
const emit = defineEmits(['updateRange'])

import { shortServerFormat } from '@tenant/utils/day'

defineProps({
    disabled: {
        type: Boolean,
        default: false,
    },
})
const currentDate = new Date()
const frequency = ref('monthly')
const fromDate = ref(shortServerFormat(currentDate))
const toDate = ref(fromDate.value)

const _syncDateRange = (date, frequency, direction, addDates = 0) => {
    date = new Date(date)
    let mutationDate
    if (frequency === 'monthly') {
        mutationDate = new Date(date.setMonth(date.getMonth() + direction))
    } else if (frequency === 'quarterly') {
        mutationDate = new Date(date.setMonth(date.getMonth() + direction * 3))
    } else {
        mutationDate = new Date(
            date.setFullYear(date.getFullYear() + direction)
        )
    }

    mutationDate.setDate(mutationDate.getDate() + addDates)

    return shortServerFormat(mutationDate)
}

const emitChange = () => {
    emit('updateRange', {
        fromDate: fromDate.value,
        toDate: toDate.value,
        step: frequency.value,
    })
}

const leftBack = (e) => {
    e.stopPropagation()
    fromDate.value = _syncDateRange(fromDate.value, frequency.value, -1)
    toDate.value = _syncDateRange(toDate.value, frequency.value, -1)
    emitChange()
}

const rightForward = (e) => {
    e.stopPropagation()
    fromDate.value = _syncDateRange(fromDate.value, frequency.value, 1)
    toDate.value = _syncDateRange(toDate.value, frequency.value, 1)
    emitChange()
}

watch(
    frequency,
    () => {
        if (frequency.value === 'yearly') {
            fromDate.value = shortServerFormat(
                new Date(new Date().getFullYear(), 0, 1)
            )
        }

        toDate.value = _syncDateRange(fromDate.value, frequency.value, 1, -1)
        emitChange()
    },
    { immediate: true }
)

//watch from date and to date
const onchangeFromDate = () => {
    toDate.value = _syncDateRange(fromDate.value, frequency.value, 1, -1)
    emitChange()
}

const onchangeToDate = () => {
    fromDate.value = _syncDateRange(toDate.value, frequency.value, -1, 1)
    emitChange()
}
</script>
