import { GUARD } from '@tenant/utils/constants'

const routes = [
    {
        name: 'subscription-expenses',
        path: '/subscription-expenses',
        redirect: {
            name: 'subscription-expenses.overview',
        },
        meta: {
            closeSidebar: false,
        },
        children: [
            {
                name: 'subscription-expenses.overview',
                path: 'overview',
                component: () =>
                    import('./pages/subscription-expenses-overview.vue'),
            },
            {
                name: 'subscription-expenses.timesheet',
                path: 'timesheet',
                component: () =>
                    import('./pages/subscription-expenses-timesheet.vue'),
            },
            {
                name: 'subscription-expenses.timesheet.detail',
                path: 'timesheet/transaction/:id',
                component: () =>
                    import(
                        './pages/subscription-expenses-timesheet-detail.vue'
                    ),
            },
        ],
    },
]

export default {
    routes,
    meta: {
        layout: 'dashboard-layout',
        guard: GUARD.TENANT,
        permission: 'view_subscription_expenses',
    },
}
