<template>
    <base-sticky-heading v-if="subscription" content-class="bg-gray-50 p-6">
        <template #left>
            <div class="flex flex-row flex-wrap items-center gap-4 self-center">
                <strong class="py-2 text-xl font-medium text-gray-900">
                    {{
                        subscription.type === SUBSCRIPTION.TYPE_CONFIRMED
                            ? $t(
                                  'subscriptionExpenses.timesheet.confirmed.title'
                              )
                            : $t(
                                  'subscriptionExpenses.timesheet.possible.title'
                              )
                    }}
                </strong>
            </div>
        </template>

        <div class="grid w-full grid-cols-3 items-start gap-6 self-stretch">
            <div
                class="col-span-2 flex h-[542px] flex-col items-start self-stretch rounded-[5px] border border-gray-300 bg-white"
            >
                <div
                    class="flex flex-col items-start gap-4 self-stretch rounded-[8px] border border-l-0 border-r-0 border-t-0 border-gray-200 px-4 py-3"
                >
                    <base-button-group>
                        <base-button
                            variant="default"
                            :class="{
                                'bg-gray-100': layoutView === 'transactions',
                            }"
                            @click="viewChange('transactions')"
                        >
                            {{
                                $t(
                                    'subscriptionExpenses.timesheet.transaction.detail.transactions'
                                )
                            }}
                        </base-button>
                        <base-button
                            variant="default"
                            :class="{
                                'bg-gray-100': layoutView === 'comments',
                            }"
                            @click="viewChange('comments')"
                        >
                            {{
                                $t(
                                    'subscriptionExpenses.timesheet.transaction.detail.comments'
                                )
                            }}
                        </base-button>
                        <base-button
                            variant="default"
                            :class="{
                                'bg-gray-100': layoutView === 'activity_log',
                            }"
                            @click="viewChange('activity_log')"
                        >
                            {{
                                $t(
                                    'subscriptionExpenses.timesheet.transaction.detail.activityLog'
                                )
                            }}
                        </base-button>
                    </base-button-group>
                </div>

                <div class="h-full w-full flex-1 overflow-auto">
                    <subscription-expenses-timesheet-detail-transactions
                        v-if="layoutView === 'transactions' && subscription"
                        :loading="loading"
                        :transactions="subscription.transactions"
                        :defaultCurrency="defaultCurrency"
                    />

                    <subscription-expenses-log
                        v-if="layoutView === 'activity_log' && subscription"
                        :key="refSubscriptionExpensesLog"
                    />

                    <subscription-expenses-detail-comment
                        v-if="layoutView === 'comments' && subscription"
                    />
                </div>
            </div>

            <subscription-expenses-timesheet-detail-right-card
                v-if="subscription"
                :subscription="subscription"
                :loading="loading"
                :defaultCurrency="defaultCurrency"
                @confirm="onConfirm"
                @notConfirm="onNotConfirm"
                @ignore="onIgnore"
                @save="onSave"
            />
        </div>
    </base-sticky-heading>
</template>

<script setup>
import { SUBSCRIPTION } from '@tenant/modules/tenant/subscription-expenses/constant/subscription'
import { useSubscriptionExpensesTimesheetDetail } from '@tenant/modules/tenant/subscription-expenses/composables/use-subscription-expenses-timesheet-detail'

const {
    execute,
    loading,
    subscription,
    save,
    ignore,
    confirm,
    notSubscription,
} = useSubscriptionExpensesTimesheetDetail()
const { defaultCurrency } = useCurrencies()
const layoutView = ref('transactions')
const route = useRoute()
const router = useRouter()

const emitter = useEmitter()
const { setValues, setErrors, handleSubmit, errors } = useForm()
const viewChange = (type) => {
    layoutView.value = type
}

const refSubscriptionExpensesLog = ref(0)

onMounted(() => {
    fetchSubscription()
})

const fetchSubscription = async () => {
    await execute(route.params.id)

    setValues({
        frequency: subscription.value?.frequency,
        next_payment_date: subscription.value?.renewalDate,
        status: subscription.value?.status ?? false,
        app: subscription.value?.app,
    })

    if (layoutView.value === 'activity_log') {
        refSubscriptionExpensesLog.value++
    }
}

const onSave = handleSubmit(async (values) => {
    try {
        emitter.emit('set-loading', true)
        await save(route.params.id, values)
        await fetchSubscription()
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
})

const onIgnore = async () => {
    try {
        emitter.emit('set-loading', true)
        await ignore(route.params.id)

        router.push({
            name: 'subscription-expenses.timesheet',
        })
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
}

const onConfirm = async () => {
    try {
        await onSave()
        if (errors.value && errors.value.length) {
            return
        }

        emitter.emit('set-loading', true)

        await confirm(route.params.id)

        await fetchSubscription()
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
}

const onNotConfirm = async () => {
    try {
        emitter.emit('set-loading', true)
        await notSubscription(route.params.id)

        router.push({
            name: 'subscription-expenses.timesheet',
        })
    } catch ({ errors }) {
        setErrors(errors)
    } finally {
        emitter.emit('set-loading', false)
    }
}

watch(
    () => route.params.id,
    (id) => {
        if (route.name !== 'subscription-expenses.timesheet.detail') {
            return
        }

        if (id) {
            fetchSubscription()
        }
    }
)
</script>
